import logo from "./assets/logo.png";

const pushNotification = (body) => {
    if (Notification.permission !== "granted") {
        Notification.requestPermission();
    } else {
        const notification = new Notification("Misas", {
            body: body,
            icon: logo,
        });
        notification.onclick = () => {
            window.location.href = "/notification";
        };
    }
};

export { pushNotification };