import React, { useEffect, useState } from "react";
import weather from "../assets/weather.png";
import doctorp from "../assets/doctorp.png";
import drpassport from "../assets/doc.jpg";
import clinic from "../assets/clinic.jpg";
import { BsBellFill, BsFillCalendar2DateFill } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { AiFillClockCircle } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import BottomNav from "./BottomNav";
import AppointmentCard from "./Patient/PatientCard";
import { getAppointmentAction, getClinicsAction, getDoctorsAction } from "../redux/actions/appointmentActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Loading from "./Loading";
import { logoutUserAction } from "../redux/actions/userActions";

function HomeDashboard() {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [notifications, setNotifications] = useState(
    JSON.parse(localStorage.getItem("notifications"))
  );

  const dispatch = useDispatch();
  const store = useSelector((state) => state.getAppointmentAction);
  const docStore = useSelector((state) => state.getDoctorsAction);
  const logout = useSelector((state) => state.logOutUser);

  const clickHandler = () => {
    setActive(!active);
  };

  const handleLogout = () => {
    dispatch(logoutUserAction());
    setTimeout(() => {
      if (logout.success) {
        navigate('/login')
      }
    }, 400);
  };

  const requestPermission = () => {
    //ask for permission to use windows Notifications
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
    //if permission is denied, show a message to the user
    if (Notification.permission === "denied") {
      Notification.requestPermission();
    }
    //if permission is not granted ask for permission
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userInfo") === null) {
      navigate("/login");
    }

    // requestPermission();
   
  }, [store.success, logout.success]);


  return (
    <div className="h-auto flex flex-col justify-center items-center">
      <div className="w-full md:w-[590px] h-auto md:h-auto relative rounded-md  shadow-xl">
        <div className="w-full h-[328px] bg-primary100 rounded-b-[5rem] relative">
          <div className="flex justify-between p-5">
            <div className="">
              <div className="flex">
                <span className="text-[1.5rem] text-white">
                  {
                    // greeting based on time of the day
                    new Date().getHours() < 12
                      ?
                      `Good Morning ${JSON.parse(localStorage.getItem("userInfo"))
                        ?.name
                      }`
                      : new Date().getHours() < 18
                        ?
                        `Good Afternoon ${JSON.parse(localStorage.getItem("userInfo"))
                          ?.name
                        }`
                     
                        :
                        `Good Evening  ${JSON.parse(localStorage.getItem("userInfo"))
                          ?.name
                        }`
                    
                  }
                </span>
              </div>
              <p className="text-[2rem] text-white mt-2 font-bold">
                {JSON.parse(localStorage.getItem("hospital_name"))?.description ?? 'Hospital' }
              </p>
            </div>
            <div className="flex">
              <HiOutlineDotsVertical
                className="text-white text-[1.5rem] cursor-pointer"
                onClick={clickHandler}
              />
              {active ? (
                <p
                  onClick={handleLogout}
                  className="text-white text-[1rem] cursor-pointer"
                >
                  <FiLogOut
                    className="ml-1 text-white text-[1.5rem]"
                    title="Log Out"
                  />
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="w-[90%] h-[177px] bg-white absolute bottom-[-5rem] right-[1.5rem] rounded-md shadow-lg">
            <div className="flex justify-between items-center px-4">
              <div className="w-[300px] ">
                <p className="text-[16px] font-bold">
                  You can now view limited report on Misas Everywhere you go !
                </p>
                <Link to="/patient-record">
                  <button className="p-2 rounded-lg bg-primary100 text-white mt-7 shadow-lg">
                    View Patient record Now
                  </button>
                </Link>
              </div>
              <div>
                <img src={doctorp} height="177px" width="100px" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[5rem] p-4">

        </div>
        <div>
          <div>
            <p className="text-[20px] font-bold mb-1 ml-8 mt-2">
              View Records 
            </p>
            <div className="flex justify-center flex-wrap">
              <div className="flex flex-col justify-center items-center border-2 border-primary100 shadow-xl shadow-black-600  p-4 rounded-md m-2 w-40">
                <p className="font-semibold text-md text-primary100 ">
                  <Link to={'/patient-record'}>Patient Records</Link>
                </p>
               
              </div>
              <div className="flex flex-col justify-center items-center border-2 border-primary100 shadow-xl shadow-black-600  p-4 rounded-md m-2 w-40">
                <p className="font-semibold text-md text-primary100 "><Link to={'/recent-visits'}>Recent Visits</Link></p>
                
              </div>
              <div className="flex flex-col justify-center items-center border-2 border-primary100 shadow-xl shadow-black-600  p-4 rounded-md m-2 w-40">
                <p className="font-semibold text-md text-primary100 "><Link to={'/patient-record'}>Consultations</Link></p>
        
              </div>
              <div className="flex flex-col justify-center items-center border-2 border-primary100 shadow-xl shadow-black-600  p-4 rounded-md m-2 w-40">
                <p className="font-semibold text-md text-primary100 "><Link to={'/payment-history'}>Payment</Link></p>
                
              </div>
            </div>
          </div>
          
          <div className="mt-8 mb-20">
              <div className="flex justify-center items-center">
              <p className="text-ash100 text-lg">Click 
                <Link to="/change-password" className="text-primary100 font-bold mx-2">HERE</Link>
                to change your password
              </p>
              </div>
          </div>
        </div>
        <BottomNav color={"text-primary100"} />
      </div>
    </div>
  );
}

export default HomeDashboard;
