import React, { useEffect, useState } from "react";
import register from "../assets/register.png";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createUserAction, getOTp } from "../redux/actions/userActions";
import Loading from "./Loading";
import { change_password } from "../redux/actions/createPasswordAction";

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const store = useSelector(state => state.change_password);
  // const { store.loading, store.success, store.error, store.data } = store.change_password;
  
  
  
  const [state, setState] = useState({
    old_password: "",
    password: "",
    password_confirmation: "",
  });

  useEffect(() => {
    if (store.success) {
      navigate('/dashboard');
    }
    // return () => {
    //   dispatch({ type: 'CHANGE_PASSWORD_RESET' })
    // };
  }, [store.success]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    
    setState({
      ...state,
      [name]: value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!state.old_password || !state.password || !state.password_confirmation) {
      alert("All fields are required");
      return;
    }
    
    let res = dispatch(change_password(JSON.parse(localStorage.getItem('userInfo')).id, state.old_password, state.password, state.password_confirmation));
    // setState({
    //   file_number: "",
    // });
  };

  return (
    <div className="h-screen">
        <div
          className="h-1/2 relative"
          style={{
            backgroundImage: `url(${register})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "400px",
          }}
        >
          <div className="absolute bg-primary100 opacity-50 top-0 right-0 bottom-0 left-0">
            <div className="absolute bottom-5 left-1">
              <p className="text-[30px] text-white px-5">Password Update</p>
              <p className="text-[40px] text-white font-bold px-5">
                Misas Mini
              </p>
            </div>
          </div>
        </div>
        <div className="h-1/2">
          <p className="mt-5 text-ash100 text-[18px] px-5">
            Input your details to change your Password
          </p>
        <p className="text-red-500 px-5">{store.error?.password ? store.error?.password : store.error}</p>

          <div>
          <p className="mt-[1rem] text-[20px] font-bold px-5">Password</p>
          <input
            type="text"
            name="old_password"
            onChange={changeHandler}
            placeholder="Enter your old Password"
            className="py-3 w-[85%]  mx-5 px-5 outline outline-1 outline-gray-400 rounded-sm mb-[1rem]"
          />
          </div>

          <div>
          <p className="mt-[1rem] text-[20px] font-bold px-5">New Password</p>
          <input
            type="text"
            name="password"
            onChange={changeHandler}
            placeholder="Enter new Password"
            className="py-3 w-[85%]  mx-5 px-5 outline outline-1 outline-gray-400 rounded-sm mb-[1rem]"
          />
          </div>

          <div>
          <p className="mt-[1rem] text-[20px] font-bold px-5">Re-enter new Password</p>
          <input
            type="text"
            name="password_confirmation"
            onChange={changeHandler}
            placeholder="Re-enter new password"
            className="py-3 w-[85%]  mx-5 px-5 outline outline-1 outline-gray-400 rounded-sm mb-[1rem]"
          />
          </div>

          <div className="flex flex-col justify-center items-center">
            {
              store.loading ? <Loading /> : <button
                onClick={submitHandler}
                className="w-[80%] py-2 bg-primary100 rounded-md text-white text-[18px] outline outline-primary100 outline-1 mt-3 mb-5">
                Change
              </button>
            }
          
            <span className="text-[18px] mb-5 text-primary100 font-bold">
            
            <Link to={-1} className="mb-5 text-primary100 font-bold" >Back</Link>
              </span>
            </div>

          
        </div>
    </div>
  );
}

export default Register;
