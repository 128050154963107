import { SEARCH_PATIENT_REQUEST, SEARCH_PATIENT_SUCCESS, SEARCH_PATIENT_FAIL, GET_PATIENT_REQUEST, GET_PATIENT_SUCCESS, GET_PATIENT_FAIL } from "../constants/patient";
import axios from "axios";
import { baseUrl } from "./baseUrl";


export const search_patient_request = (patient) => async (dispatch) => {
    try {
        dispatch({
            type: SEARCH_PATIENT_REQUEST,
        });
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
            },
        };
        const { data } = await axios.get(
            `${baseUrl}/search_patient?search_val=${patient}`,
            config
        );

        dispatch({
            type: SEARCH_PATIENT_SUCCESS,
            payload: data,
        });
    } catch (err) {
        let message =
            err.response && err.response.data.errors
                ? err.response.data.errors
                : err.message;
        dispatch({
            type: SEARCH_PATIENT_FAIL,
            payload: message,
        });

    }
}
export const get_patient = (patient) => async (dispatch) => {
    try {
        dispatch({
            type: GET_PATIENT_REQUEST,
        });
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
            },
        };
        const { data } = await axios.get(
            `${baseUrl}/get_patient?patient=${patient}`,
            config
        );

        dispatch({
            type: GET_PATIENT_SUCCESS,
            payload: data,
        });
    } catch (err) {
        let message =
            err.response && err.response.data.errors
                ? err.response.data.errors
                : err.message;
        dispatch({
            type: GET_PATIENT_FAIL,
            payload: message,
        });

    }
}
