import React from "react";
import pana from "../assets/pana.png";
import { Link } from "react-router-dom";
function Welcome() {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="w-full md:w-[590px]  h-[638px] relative rounded-md  shadow-xl">
        <div className="flex justify-center items-center mt-5 mb-5">
          <img src={pana} width="500px" height="500px" />
        </div>
        <h1 className="flex justify-center items-center md:text-[40px] text-[24px] font-extrabold ">
          Welcome to Misas mini
        </h1>
        <p className="flex justify-center items-center text-[16px] md:text-[16px] px-[20px] mb-3 ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultricies
          quam ultricies porttitor varius ultrices id. Dolor, nunc facilisis
          turpis integer viverra.{" "}
        </p>
        <Link to="/login">
          {" "}
          <div className="flex justify-center items-center">
            <button className=" w-full py-2 bg-primary100 rounded-md text-white text-[18px] mx-5">
              Login
            </button>
          </div>
        </Link>
        {/* <Link to="/register">
          <div className="flex justify-center items-center">
            <button className=" w-full py-2 bg-white rounded-md text-primary100 text-[18px] mx-5 outline outline-primary100 outline-1 mt-3">
              Change Password
            </button>
          </div>
        </Link> */}
      </div>
    </div>
  );
}

export default Welcome;
