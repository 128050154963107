import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getNotes } from '../../redux/actions/notes'
import { get_patient } from '../../redux/actions/patient'
import BottomNav from '../BottomNav'
import Button from '../Button/Button'
import Loading from '../Loading'
import TopBar from '../TopBar'

export default function PatientDetails() {
    const dispatch = useDispatch();
    let { loading, success, error, data } = useSelector(state => state.get_patient);
    const consult = useSelector(state => state.getNotes);
    const navigate = useNavigate();

    const [state, setState] = useState();
    const [patient, setPatient] = useState();
    const [patientId, setPatientId] = useState();
    const location = useLocation().state;
    localStorage.setItem('patient_id', location);
    console.log('patiendID', patientId)
    

    useEffect(() => {
        localStorage.setItem('patient_id', location);
        if (localStorage.getItem("userInfo") === null) {
            navigate("/login");
        }
        if (patientId == null || location != patientId ) {
            dispatch(get_patient(location));
            dispatch(getNotes(location));
            setPatientId(location);
       }
        // setTimeout(() => {
            setPatient(data?.patient)
        // }, 500);
        return () => {
            success = false;
            setPatient('')
        };
    }, [success,patientId]);

    return (
        <div className="h-screen">
            <TopBar title={"Patient Details"} url={'/patient-record'} />
            <div className="mt-6 flex justify-center items-center">
                <p className='font-bold text-[22px]' >{  patient?.system_id }</p>
            </div>
          <div className="mt-4 flex flex-wrap flex-grow-1 justify-around items-center gap-x-1">
              <button className="p-2 bg-primary100 rounded-md text-white text-[18px] mb-[2rem]">
                    <Link to={'/patient-details/consultations'} >Consultation: {data?.notes_count ?? 0} </Link>
              </button>
             
              <button className="p-2 bg-primary100 rounded-md text-white text-[16px] mb-[2rem]">
                    <Link to={'/patient-details/prescriptions'} state={patient?.id}>Prescriptions: {data?.prescriptions?? 0 }</Link>
                </button>
                <button className="p-2 bg-primary100 rounded-md text-white text-[16px] mb-[2rem]">
                    <Link to={'/patient-details/diagnostic_investigation'} state={patient?.id}>Diagnostic Investigation: {data?.investigation?? 0 }</Link>
                </button>
              <button className="p-2 bg-primary100 rounded-md text-white text-[16px] mb-[2rem]">
                    <Link to={'/patient-details/diagnosis'} state={patient?.id}>Diagnosis: {data?.diagnosis?? 0 }</Link>
              </button>
            </div>
            {
                loading ? <Loading /> : 
                    <div className="flex flex-col justify-start mx-4  divide-y-4">
                        <div className="flex justify-center items-center">
                            <p className='font-bold text-[22px] text-primary100' >{ patient?.gender }</p>
                            <hr />
                        </div>
                        <p className='font-bold text-primary100 text-[18px]'>Title: { patient?.title }</p>
                        <p className='font-bold text-primary100 text-[18px]'>Last Name: {  patient?.lastname }</p>
                        <p className='font-bold text-primary100 text-[18px]'>First Name: {  patient?.firstname}</p>
                        <p className='font-bold text-primary100 text-[18px]'>Phone: { patient?.mobile }</p>
                        <p className='font-bold text-primary100 text-[18px]'>Address: { patient?.address + ", " + patient?.city + ", "+ patient?.state + ", " + patient?.country }</p>
                    </div>
            }
           

          <BottomNav color1={'text-primary100'} />
        </div>
  )
}
