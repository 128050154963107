
import axios from "axios";
import { GET_PRESCRIPTIONS_FAIL, GET_PRESCRIPTIONS_REQUEST, GET_PRESCRIPTIONS_SUCCESS } from "../constants/Prescriptions";
import { baseUrl } from "./baseUrl";

export const Prescriptions = (patient) => async (dispatch) => {
    try {
        dispatch({
            type: GET_PRESCRIPTIONS_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "Application/json",
                "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
            },
        };
        const { data } = await axios.get(
            `${baseUrl}/prescriptions?patient=${patient}`,
            config
        );

        dispatch({
            type: GET_PRESCRIPTIONS_SUCCESS,
            payload: data,
        });

    } catch (err) {
        let message =
            err.response && err.response.data.errors
                ? err.response.data.errors
                : err.message;
        dispatch({
            type: GET_PRESCRIPTIONS_FAIL,
            payload: message,
        });

    }
};


