import {
  CREATE_PASSWORD_REQUEST,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from "../constants/createPasswordConstants.js";

import axios from "axios";
import { baseUrl } from "./baseUrl.js";

const createPasswordAction =
  (phone_number, file_number, password, confirmpassword) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_PASSWORD_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "Application/json",
        },
      };
      const { data } = await axios.post(
        `${baseUrl}/create_password?phone_number=${phone_number}&file_number=${file_number}&password=${password}&password_confirmation=${confirmpassword}`,
        config
      );

      dispatch({
        type: CREATE_PASSWORD_SUCCESS,
        payload: data,
      });
    } catch (err) {
      let message =
        err.response && err.response.data.errors
          ? err.response.data.errors
          : err.message;
      dispatch({
        type: CREATE_PASSWORD_FAIL,
        payload: message,
      });

    }
  };

const change_password = (user, old_password, password, confirmpassword) => async (dispatch) => {
    try {
      dispatch({
        type: CHANGE_PASSWORD_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "Application/json",
        },
      };
      const { data } = await axios.post(
        `${baseUrl}/change_password?user=${user}&old_password=${old_password}&password=${password}&password_confirmation=${confirmpassword}`,
        config
      );

      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: data,
      });
    } catch (err) {
      let message =
        err.response && err.response.data.errors
          ? err.response.data.errors
          : err.message;
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        payload: message,
      });

    }
  };
export { createPasswordAction, change_password };
