import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Datepicker from 'react-tailwindcss-datepicker'
import Select from 'react-tailwindcss-select'
import { getPayment } from '../../redux/actions/payment'
import BottomNav from '../BottomNav'
import Button from '../Button/Button'
import DTable from '../DTable'
import Loading from '../Loading'
import TopBar from '../TopBar'

export default function Payment() {
    const navigate = useNavigate();
    const [value, setValue] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    });
    const [animal, setAnimal] = useState(null);
    const [state, setState] = useState({
        cash: 0,
        cheque: 0,
        pos: 0,
        transfer: 0,
        others: 0,
        total_paid: 0
    })
    const dispatch = useDispatch();
    let { loading, success, error, data } = useSelector(state => state.getPayment);
    

    const options = [
        { value: "fox", label: "No user available" }
    ];
    const handleValueChange = (newValue) => {
        
        setValue(newValue);
        if (newValue.startDate && newValue.endDate != null) {
            dispatch(getPayment(newValue));
        }
    }


    const handleChange = value => {
        
        setAnimal(value);
    };

    useEffect(() => {
        if (localStorage.getItem("userInfo") === null) {
            navigate("/login");
        }
        if (!success) {
            dispatch(getPayment(value));
        }
    //    setTimeout(() => {
           setState({ ...state, cash: data?.cash, cheque: data?.cheque, pos: data?.pos, transfer: data?.transfer, others: data?.others, total_paid: data?.total_paid })
    //    }, 500);

        // return () => {
        //     success = false;
        // };
    }, [loading]);
    
    return (
        <div className="h-fit">
            <TopBar title={"Payment History"} url={'/dashboard'} />
            
            <div className="mt-6 flex flex-col justify-center items-center space-y-2">
            
                <div className='w-4/6' >
                    <Datepicker
                        containerClassName={'bg-light'}
                        primaryColor='green'
                        value={value}
                        onChange={handleValueChange}
                    />
                </div>

                <div className='w-4/6' >
                    <Select
                        primaryColor='green'
                        value={animal}
                        onChange={handleChange}
                        options={options}
                    />
                </div>
            </div>
            <div className="mt-6 mx-2 flex justify-center flex-wrap space-y-2">
                <div className='shadow p-2 w-3/6' >
                    <p className='font-bold text-primary100 text-[18px]'>Total: N { state.total_paid }</p>
                </div>
                <div className='shadow p-2 w-3/6' >
                    <p className='font-bold text-primary100 text-[18px]'>Cash: N {state.cash}</p>
                </div>
                <div className='shadow p-2 w-3/6'>
                    <p className='font-bold text-primary100 text-[18px]'>Cheque: N { state.cheque }</p>
                </div>
                <div className='shadow p-2 w-3/6' >
                    <p className='font-bold text-primary100 text-[18px]'>POS: N { state.pos }</p>
                </div>
                <div className='shadow p-2 w-3/6'>
                    <p className='font-bold text-primary100 text-[18px]'>Transfer: N { state.transfer }</p>
                </div>
                <div className='shadow p-2 w-3/6'>
                    <p className='font-bold text-primary100 text-[18px]'>Others: N { state.others }</p>
                </div>
            </div>
            <div className='pb-[5rem]' >

            {
                loading ? <Loading /> : 
                    <DTable data={data?.records} />
                }
            </div>

            <BottomNav color3={'text-primary100'} />
        </div>
    )
}
