import React, { useEffect, useState } from "react";
import register from "../assets/register.png";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUserAction } from "../redux/actions/userActions";
import axios from "axios";
import Loading from "./Loading";

function FirstLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const store = useSelector((state) => state.loginUser);
  const { loading, success, error } = store;

  const [state, setState] = useState({
    file_number: "",
    password: "",
  });
  useEffect(() => {
    
    if (success) {
      navigate("/dashboard");
      // window.location.reload();
    }
  }, [success, error && error.todo]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!state.file_number || !state.password) {
      alert("all fields are required");
      return;
    }

    dispatch(loginUserAction(state.file_number, state.password));
    // setState({
    //   file_number: "",
    //   password: "",
    // });
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="w-full md:w-[590px] h-screen md:h-[638px] relative rounded-md  shadow-xl">
        <div
          className="h-1/2 w-[100px] relative"
          style={{
            // backgroundImage: `url(${register})`,
            backgroundImage: `url(https://misas.com.ng/assets/images/logos/misaslogo.png)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            width: "100%",
            height: "300px",
          }}
        >
          <div className="absolute bg-primary100 opacity-50 top-0 right-0 bottom-0 left-0">
           
          </div>
        </div>
        <div className="h-1/2">
          <div className="text-primary100">
            {/* <p className="text-[30px] px-3">Welcome to</p> */}
            <p className="text-[40px] font-bold px-3">
              Misas Mini
            </p>
          </div>
          <div className="flex flex-col justify-center items-center w-full">
          <p className="mt-3 text-[18px] px-3">
            Input your Username and password to login
          </p>
            <span className="text-red-500 px-3" >{error ? error.message : ''}</span>
            <div className="w-5/6">
          <p className="mt-[1rem] text-[20px] font-bold">Username or Email</p>
          <input
            type="text"
            name="file_number"
            value={state.file_number}
            placeholder="Enter your email or username"
            onChange={changeHandler}
            className="py-3 px-3 w-full rounded text-[16px] text-black outline outline-1 outline-green-400 rounded-sm mb-[2rem]" />
            </div>

            <div className="w-5/6">
          <p className="text-[20px] font-bold">Password</p>
          <input
            type="password"
            placeholder="Enter your password"
            onChange={changeHandler}
                className="py-3 px-3 w-full rounded text-[16px] text-black outline outline-1 outline-green-400 rounded-sm mb-[2rem]"
            name="password"
            value={state.password}
            />
            </div>
          </div>
          {
            loading ? <Loading /> :
              <div className="flex flex-col justify-center items-center">
              <button
                onClick={submitHandler}
                className="w-5/6 py-2 bg-primary100 rounded-md text-white text-[18px] mb-[2rem]">
                Log In
              </button>
             
            </div>
          }
         
        </div>
      </div>
    </div>
  );
}

export default FirstLogin;
