import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { get_clinic_name, visits_summary } from '../../redux/actions/visit'
import BottomNav from '../BottomNav'
import Button from '../Button/Button'
import Loading from '../Loading'
import TopBar from '../TopBar'

export default function Visits() {
    const navigate = useNavigate();
    const store = useSelector(state => state);
    const { success, error, loading, data } = store?.visits_summary_reducer;
    const clinic = store?.get_clinic_name;
    
    const dispatch = useDispatch();
    const [totalVisits, setTotalVisits] = useState();
    const [clinicVisits, setClinicVisits] = useState([]);

    useEffect(() => {
        if (localStorage.getItem("userInfo") === null) {
            navigate("/login");
        }
        dispatch(visits_summary());
        dispatch(get_clinic_name())
        setTotalVisits(data?.total_visits);
        setClinicVisits(data?.clinics)
    }, [success]);
    return (
        <div className="h-screen">
            <TopBar title={"Recent Visits"} url={'/dashboard'} />
            <div className="mt-8 flex justify-between">
                <div className="flex flex-col justify-center items-center border-2 border-primary100 shadow-xl shadow-black-600 p-3 rounded-md m-3 w-full">
                    <p className="font-semibold text-md">Total Visits this month:</p>
                    <p className="text-primary100 font-bold text-4xl">
                        { totalVisits?? 0 }
                    </p>
                </div>
            </div>
           
           
            <section>
                <div className="mt-8 flex flex-col justify-start mx-4 divide-y-4 divide-primary100 space-y-3">
                    {
                        loading ? <Loading /> : clinicVisits?.map((item) => 
                            <p className='font-bold text-primary100 text-[18px]'>{clinic.data?.clinic?.find(({ id }) => id == item.clinic_id).name }: { item.visits?? 0}</p>
                        )
                    }
                </div>
            </section>

            <BottomNav color2={'text-primary100'} />
        </div>
    )
}
